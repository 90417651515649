import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { ContactFormIndex } from '../forms/ContactFormIndex'

const BusinessesPageTemplate = (props) => {
  return (
    <div>
      <Helmet>
        <title>Nimber Inc</title>
        <meta name='description' content='Nimber Inc' />
      </Helmet>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered is-centered'>
              <div className='column'>
                <img src='/img/businesses1-txt.png' width='447' height='294' />
              </div>
              <div className='column'>
                <img src='/img/businesses1.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-medium background-business-1'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/businesses2.png'  width='447' height='294'/>
              </div>
              <div className='column'>
                <img src='/img/businesses2-txt.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/businesses3-txt.png' width='447' height='294'/>
              </div>
              <div className='column'>
                <img src='/img/businesses3.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/businesses4.png' />
              </div>
              <div className='column'>
                <img src='/img/businesses4-txt.png' width='585' height='472'/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BusinessesPageTemplate
